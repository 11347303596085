import React, { useState, useEffect } from "react";
import { Button, Card, Form, Container, Col } from "react-bootstrap";

import { FormHelper } from "utils";
import useMessage from "hooks/useMessage";
import { useTheme, useAuthentication } from "hooks";
import { ResetPasswoodPage } from "./ResetPassword";
import { ForgotPasswordPage } from "./ForgotPassword";
import useExceptionHandler from "hooks/useExceptionHandler";
import { AnimatedIconButton } from "components/AnimatedIconButton/AnimatedIconButton";

export function LoginPage({ showLoading, hideLoading }) {
  const [cardClasses, setCardClasses] = useState("card-hidden");
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [form, setForm] = useState({
    nombreUsuario: "",
    contrasena: "",
    codigoCompania: "",
  });

  const { theme } = useTheme();
  const { login } = useAuthentication();
  const { cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();

  const urlParams = new URLSearchParams(window.location.search);
  const em = urlParams.get("em");
  const cc = urlParams.get("cc");

  useEffect(() => {
    if (em && cc) setIsResetPassword(true);

    return () => {
      cerrarAlertas();
    };
  }, []);

  const onInputChange = (e) => {
    FormHelper.handleChange(e, setForm);
  };

  const onKeyDownEvent = (e) => {
    if (e.keyCode === 13) {
      //enter
      onSubmit(e);
    }
  };

  const onSubmit = (e) => {
    showLoading();
    FormHelper.handleSubmit(e);
    login(form)
      .then(() => {})
      .catch((error) => handleException(error))
      .finally(() => hideLoading());
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };

  const forgotPasswordButton = (
    <Button
      variant="link"
      type="button"
      style={{ color: theme.color1, paddingTop: "30px" }}
      onClick={handleForgotPassword}
    >
      ¿Olvidaste tu contraseña?
    </Button>
  );

  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  }, []);

  const page = () => {
    if (isForgotPassword) {
      return (
        <ForgotPasswordPage
          showLoading={showLoading}
          hideLoading={hideLoading}
          setIsForgotPassword={setIsForgotPassword}
        />
      );
    } else if (isResetPassword) {
      return (
        <ResetPasswoodPage
          showLoading={showLoading}
          hideLoading={hideLoading}
          setIsResetPassword={setIsResetPassword}
        />
      );
    } else {
      return (
        <div className="full-page section-image" data-color="black">
          <div className="content d-flex align-items-center justify-content-center p-0">
            <Container
              className="d-flex align-items-center justify-content-center"
              style={{ maxWidth: "65%", minWidth: "65%" }}
            >
              <Card
                className={"card-login" + cardClasses}
                style={{ minWidth: "20rem", maxWidth: "40rem" }}
              >
                <Card.Header className="text-center">
                  {theme.logo ? (
                    <img
                      alt="logo"
                      src={theme.logo}
                      height={theme.logoHeight}
                      width={theme.logoWidth}
                    />
                  ) : (
                    theme.appName
                  )}
                </Card.Header>
                <Card.Body
                  style={{
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "0px",
                  }}
                >
                  <Form className="form" onSubmit={onSubmit}>
                    <Form.Group>
                      <label>Usuario</label>
                      <Form.Control
                        autoComplete="username"
                        name="nombreUsuario"
                        type="text"
                        value={form.nombreUsuario}
                        onChange={onInputChange}
                        onKeyDown={onKeyDownEvent}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label>Contraseña</label>
                      <Form.Control
                        autoComplete="current-password"
                        name="contrasena"
                        type="password"
                        value={form.contrasena}
                        onChange={onInputChange}
                        onKeyDown={onKeyDownEvent}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label>Código de Compañía</label>
                      <Form.Control
                        name="codigoCompania"
                        type="text"
                        value={form.codigoCompania}
                        onChange={onInputChange}
                        onKeyDown={onKeyDownEvent}
                      />
                    </Form.Group>
                  </Form>
                </Card.Body>
                <Card.Footer className="p-0 ml-auto mr-auto">
                  <AnimatedIconButton
                    key={"submitLoginBtn"}
                    size="60px"
                    height="35px"
                    width="35px"
                    iconName="key"
                    message="Iniciar Sesión"
                    style={{ padding: "0px" }}
                    onClick={(e) => onSubmit(e)}
                    useVendorTheme={true}
                  />
                </Card.Footer>
                {forgotPasswordButton}
              </Card>
            </Container>
          </div>
        </div>
      );
    }
  };

  return <>{page()}</>;
}
